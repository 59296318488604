<template>
  <v-container fluid class="pa-2">
    <!-- Quick Links -->
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="6">
        <v-toolbar class="primary darken-1 white--text">
          <v-spacer></v-spacer>
          <v-toolbar-title>Home</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center">
        <v-card width="900px" class="pa-4" elevation="4">
          <v-row>
            <v-col sm="12" lg="4" v-for="(card, index) in cards" :key="card.title" class="py-4 px-4">
              <core-btn-navigation
                :color="getColor(index)"
                tile
                inverted
                block
                x-large
                outlined
                height="100px"
                :app="card.app"
                :page="card.page">
                {{ card.title }}<v-icon class="ml-2">{{ card.icon }}</v-icon>
              </core-btn-navigation>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as functions from "@/javascript/functions"
import * as router from "@/javascript/router"

export default {
  name: "landing_page",
  components: {},
  data: function () {
    return {
      cards: [
        {
          title: "About",
          app: "about",
          page: "about",
          icon: "mdi-information-outline",
        },
        {
          title: "SRS Flashcards",
          app: "flashcards",
          page: "learn",
          icon: "mdi-card-multiple",
        },
        {
          title: "Settings",
          app: "flashcards",
          page: "settings",
          icon: "mdi-cog",
        },
      ],
    }
  },
  methods: {
    getColor(index) {
      let colorA = "primary"
      let colorB = "secondary"
      let colors = [colorA, colorB]

      return colors[index % 2] // flip-flops button colors
    },
  },
}
</script>
