<template>
  <v-btn :color="color" :outlined="inverted" v-bind="$attrs" v-on="$listeners" @click="navigate(app, page)">
    <slot></slot
  ></v-btn>
</template>

<script>
import * as functions from "@/javascript/functions"
export default {
  name: "core-btn-navigation",
  props: {
    color: { default: "secondary", type: String },
    inverted: Boolean,
    app: String,
    page: String,
  },
  components: {},
  methods: {
    navigate(app, pageName) {
      let pageSlug = functions.nameToSlug(pageName)
      let appSlug = functions.nameToSlug(app)

      this.$router.push({
        name: "App Page",
        params: { app_slug: appSlug, page_slug: pageSlug },
      })
    },
  },
}
</script>
